/**
 * Direttiva che forza l'autofocus della barra di ricerca libera una volta che questa è stata aperta
 */
angular.module('app').directive("autoFocus", ($timeout) => {
  return {
		restrict: 'AC',
    link: (scope: any, element: any, attr: any) => {
			// Mi collego all'input di ricerca
			let inputText = angular.element( document.querySelector('.free-search'));

			// e al click della lente che apre la barra di ricerca, imposto l'autofocus automatico sull'input
      element.bind('click', () => {
				$timeout(() => {
					inputText[0].focus();
				}, 0);
      });
		}
  };
})